// front-end
export function stateValidateBR(state) {
  if (!state) return true;

  const states = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
  // console.log("states.includes(state) " + states.includes(state));
  return states.includes(state);
};

export function helpLink(t, title, pageId, section) {
  // console.log("title = ", title);
  // console.log("pageId = ", pageId);

  if (!title) {
    return null
  };
  if (!section) {
    section = "";
  };

  var url = "";
  // if (sessionStorage.locale.substring(0, 2) === "pt") {
  url = "https://ajuda.blue21.com.br/";
  // };

  // module
  let sessionModule = "";
  if (sessionStorage.pageId == "enterprise" || pageId.match(/Enterprise/)) {
    sessionModule = "Company";
  } else if (sessionStorage.pageId.toLowerCase().match(/invoice/) || pageId.toLowerCase().match(/invoice/)) {
    sessionModule = "Commercial"
  } else if (sessionStorage.pageId.toLowerCase().match(/devolution/) || pageId.toLowerCase().match(/devolution/)) {
    sessionModule = "Commercial"
  } else if (!sessionStorage.module || pageId == "Menu") {
    sessionModule = "Access";
  } else {
    sessionModule = sessionStorage.module
  }
  let module = "";
  if (pageId != "MenuModule") {
    module = (
      t("user.Module") +
      "-" +
      t("common." + sessionModule)
    ).toLowerCase();
    module += "/"
  }

  // title
  title = title + ".html";
  title = title.toLowerCase();
  title = title.replace(/ /g, "-");

  // section
  title = title + section.toLowerCase();

  // link
  let link = (url + module + title)
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  // console.log("link =", link);
  window.open(link, "_blank");
  return;
};

import {
  postalCodeList
} from "@/json/postalCodeData.js";

export function sharedCompanies(companyId, groupId, enterprises, module) {
  if (!enterprises || enterprises.length <= 1) return "";
  //
  let company = "-" + companyId + "-";
  //
  if (module == "Company") {
    enterprises = enterprises.filter((obj) => {
      return (
        obj.groupId == groupId &&
        (obj.share.companyShare.includes(company) ||
          obj.companyId == companyId)
      );
    });
  } else if (module == "Common") {
    enterprises = enterprises.filter((obj) => {
      return (
        obj.groupId == groupId &&
        (obj.share.commonShare.includes(company) ||
          obj.companyId == companyId)
      );
    });
  } else if (module == "Stock") {
    enterprises = enterprises.filter((obj) => {
      return (
        obj.groupId == groupId &&
        (obj.share.stockShare.includes(company) ||
          obj.companyId == companyId)
      );
    });
  }
  // console.log(" enterprises " + JSON.stringify(enterprises));

  //
  return "[" + enterprises.map((row) => row.companyId) + "]";
};

export function timeBetweenDates(start, end) {
  // console.log("start = ", start);
  // console.log("end   = ", end);

  if (!start) {
    return null
  };

  start = new Date(start);
  if (end) {
    end = new Date(end);
  } else {
    end = new Date();
  }

  var difference = new Date(end - start);
  var elapsed = "";
  let hours = difference.getUTCHours();
  // console.log("hours", hours);
  if (hours > 0) {
    elapsed = hours + "h ";
  }

  elapsed += difference.getUTCMinutes() + "m ";
  // elapsed += difference.getUTCSeconds() + "s ";
  // console.log(elapsed);

  return elapsed;
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function convertSecondsToHoursAndMinutes(seconds, locale) {
  // console.log("locale = " + JSON.stringify(locale));

  // console.log("2 seconds           = " + seconds);
  const totalMinutes = Math.round(seconds / 60);
  // console.log("3 totalMinutes           = " + totalMinutes);
  if (Number(totalMinutes) < 60) return totalMinutes + " min";
  //
  var hours = Math.floor(totalMinutes / 60);
  hours = hours.toString().padStart(2, "0");
  var minutes = (totalMinutes % 60).toString().padStart(2, "0");
  //
  return hours + " h " + minutes + " min";;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function validatePostalCode(postalCode, country) {
  var obj = postalCodeList();

  let index = obj.findIndex(x => x.code === country);
  if (index == -1) return "";

  let reg = obj[index].reg
  // console.log("reg = " + reg);
  var pattern = new RegExp(reg);
  if (!pattern.test(postalCode)) return "Invalid Postal Code";
  if (country = "BR" && postalCode.length > 9) return "Invalid Postal Code";

  return "";

};


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function validateGtin(gtin) {
  // console.log("gtin = " + gtin);

  if (gtin.length !== 8 && gtin.length !== 13 && gtin.length !== 14) return "GTIN valid only with 8, 13 or 14 digits"

  var checkSum = gtin.split("").reduce(function (p, v, i) {
    return i % 2 == 0 ? p + 1 * v : p + 3 * v;
  }, 0);
  if (checkSum % 10 != 0) {
    return "Invalid GTIN";
  } else {
    return "";
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function formatCnpjCpf(value) {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function firstUpperCase(value) {
  if (!value) return ''
  if (value.trim() == '') return ''

  // implemtada a partir  https://pt.stackoverflow.com/questions/32086/converter-cada-primeira-letra-de-cada-palavra-em-maiu%CC%81sculas/32440
  let loweredText = value.toLowerCase()
  loweredText = loweredText.replace(/ {3}/g, ' ') // NEGRAO   FILIAL  RESENDE- RJ
  loweredText = loweredText.replace(/ {2}/g, ' ')

  const words = loweredText.split(' ')
  const ignore = ['e', 'a', 'de', 'da', 'das', 'do', 'dos', 'xxi']
  // console.log('0 - words.length = ' + words.length);
  // console.log('loweredText = ' + loweredText);

  for (let i = 0; i < words.length; i++) {
    let word = words[i]
    const firstLetter = word[0]

    if (i == 0 && word.length < 3) {
      word = firstLetter.toUpperCase() + word.slice(1)
    } else {
      if (ignore.indexOf(word) === -1) {
        word = firstLetter.toUpperCase() + word.slice(1)
      } else {
        word = firstLetter + word.slice(1)
      };
    }
    words[i] = word
  }
  return words.join(' ')
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function cleanObject(o) {
  // https://stackoverflow.com/questions/684575/how-to-quickly-clear-a-javascript-object
  for (const [key, value] of Object.entries(o)) {
    const propType = typeof o[key]
    // console.log(key, value, propType);
    switch (propType) {
      case 'number':
        o[key] = 0
        break
      case 'string':
        o[key] = ''
        break
      case 'boolean':
        o[key] = false
        break
      case 'undefined':
        o[key] = undefined
        break
      default:
        if (value === null) {
          continue
        }
        cleanObject(o[key])
        break
    }
  }
};
